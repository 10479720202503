import React, { useState } from "react"
import photoService from "../../../services/photo"
import media from "../../../services/media"
import FeaturedPhotosDetailsEdit from "../featured-photos-details-edit/featured-photos-details-edit"
import InnerLayout from "../../layout/inner-layout/inner-layout"
const ViewFeaturedPhotos = ({ item, isCreate, children, ...props }) => {
  const [editState, setEditState] = useState(isCreate ? true : false)
  const [itemTags, setItemTags] = useState([])
  const [message, setMessage] = useState(null)
  const [messageType, setMessageType] = useState("success")

  const handleEditRequestError = (error, resolve) => {
    setErrorMessage("An error has occurred")
    console.log("view-photo: ERROR", error)
    resolve(false)
  }

  const setErrorMessage = message => {
    setMessage(message)
    setMessageType("error")
  }
  const setSuccessMessage = message => {
    setMessage(message)
    setMessageType("success")
  }

  const setInfoMessage = message => {
    setMessage(message)
    setMessageType("info")
  }
  // This should probably be split into two or more functions
  const toggleEditing = (
    modifiedItem,
    itemPublished,
    id,
    itemDeleted,
  ) => {
    return new Promise(resolve => {
      if (itemDeleted) {
        setInfoMessage("Deleting Photo...")

        // if (id === undefined) {
        //   setErrorMessage("Photos need to be saved")
        // }
        // else {
          photoService.deletePhoto(id).then(
            deletedStory => {
              setSuccessMessage("Photo deleted successfully")
              // return to search results? Display message saying story has been deleted?
              setEditState(false)
              resolve(true)
            },
            error => {
              handleEditRequestError(error, resolve)
            }
          )
        // }
      } else if (modifiedItem && !itemPublished) {
        setInfoMessage("Saving Photos...")

        console.log('Modified', modifiedItem.media)
        
        _.map(modifiedItem.media, (modified) => {
          console.log('Modified', modified.photoId)

          let branch = ''
          if (process.env.GATSBY_USE_FHAA === 'true') {
            branch = 'fhaa'
          }
          else {
            branch = 'afpa'
          }

          if (modified.photoId === undefined) {
            const url = { 
              thumbnailUrl: modified.thumbnailUrl,
              mediaId: modified.id,
              branch
            } 

            photoService.savePhoto(url).then(
              savedPhoto => {
                setSuccessMessage("Photos updated successfully")
                // refresh the page? Probably enough to just extend item with the savedStory
                // _.extend(item, savedPhoto)
                setEditState(false)
                resolve(true)
              },
              error => {
                handleEditRequestError(error, resolve)
              }
            )
          }  
        })  
      } 
      else {
        // user cancelled
        setSuccessMessage(null)
        setEditState(false)
        resolve(true)
      }
    })
  }

  return (
      <InnerLayout>
        <FeaturedPhotosDetailsEdit
          message={message}
          messageType={messageType}
          item={item}
          itemTags={itemTags}
          setEditStateFn={toggleEditing}
        ></FeaturedPhotosDetailsEdit>
      </InnerLayout>
  )
}

export default ViewFeaturedPhotos
